import { $post } from '../../api/http.js'
import commonModel from '../../api/common.js'
import tool from '@/util/tool.js'
import moment from "moment"

class Auth{
    /**
     * 获取员工列表
     * @param {number} page 当前页
     * @param {number} limit 每页显示数量
     * @param {object} fn 
     */
    getEmployee(page,limit,extra,fn) {
        let param = { page,per_page:limit}
        if( extra ){
            if( extra.company_id ) param.company_id = extra.company_id
            if( extra.type ) param.type = extra.type
            if( extra.keyword ) param.keyword = extra.keyword
        }
        $post("getStaffUserList",param,true,1).then(res=>{
            fn(res)
        })
    }
    /**
     * 获取角色列表
     * @param {number} page 当页面
     * @param {number} limit 每页显示数量
     * @param {object} fn 
     */
    getRole(page,limit,fn) {
        let param = ( page,limit)
        commonModel.getDataList("getAuthGroupList",param,1).then(res=>{
            fn(res)
        })
    }
    
    /**
     * 获取权限菜单
     * @param {function} fn 
     */
    getAuthMenu(fn) {
        $post("getAuthGroupMenuList",{},true,1).then(res=>{
            fn(res.data.addons)
        })
    }
    
    /**
     * 新增/编辑角色信息
     * @param {object} data 保存的数据
     * @param {function} fn 
     * @returns 
     */
    addOrEditRole(data,fn) {
        if( !data.title ){
            tool.message("请填写角色名称","warning")
            return
        }
        if( !data.id ) delete data.id
        $post("addOrEditAuthGroup",data,true,1).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }

    
    /**
     * 获取角色详细信息
     * @param {number} id 
     * @param {function} fn 
     */
    getRoleDetail(id,fn) {
        $post("getAuthGroup",{group_id:id},true,1).then(res=>{
            fn(res.data)
        })
    }
    /**
     * 删除角色信息
     * @param {number} id 
     * @param {function} fn 
     */
    deleteRole(id,fn) {
        tool.confirm("确认要删除该角色信息吗?").then(()=>{
            $post("delAuthGroup",{group_id:id},true,1).then(res=>{
                tool.message("删除成功")
                fn(res.data)
            })

        }).catch(()=>{})
    }
    
    /**
     * 新增或编辑员工信息
     * @param {string} sence add/edit新增/编辑  password修改密码 
     * @param {object} data 
     * @param {function} fn 
     * @returns 
     */
    addOrEditEmployee(sence,data,fn) {
        let param = {
            name: data.name,
            mobile: data.mobile,
        }
        //新增或编辑
        if( sence == 'add') {
            if( !data.name ){
                tool.message("请填写用户名","warning")
                return
            }
            if( !data.user_id ){    //新增
                if( !data.password ){
                    tool.message("请填写登录密码","warning")
                    return
                }
                param.password = data.password
            }else{           //编辑
                param.scene= 'edit'
                param.user_id= data.user_id
                param.type= 2
            }
        }

        //修改密码
        if( sence == 'password'){
            if( !data.password ){
                tool.message("请输入新密码","warning")
                return
            }
            if( !data.re_password ){
                tool.message("请再次输入新密码","warning")
                return
            }
            if( data.password != data.re_password ){
                tool.message("两次输入密码不一致","warning")
                return
            }

            param = {
                scene:'password',
                user_id:data.user_id,
                password:data.password,
                type:2
            }
        }
        $post("addOrEditStaffUser",param,true,1).then(res=>{
            tool.message( sence == "password" ?"密码修改成功" : "保存成功")
            fn(res.data)
        })
    }

    /**
     * 分配角色
     * @param {number} user_id  用户ID
     * @param {array} group_id  角色ID
     * @param {function} fn 
     * @returns 
     */
    allocationRole(user_id,group_id,fn){
        if( group_id.length == 0){
            tool.message("请选择角色","warning")
            return
        }
        let param = {
            group_ids:group_id,
            user_id: user_id
        }
        $post("createOrUpdateAuthGroupAccess",param,true,1).then(res=>{
            tool.message("设置成功")
            fn(res.data)
        })
    }

    /**
     * 获取员工的应用列表
     * @param {number} user_id 员工UserID
     * @param {function} fn 
     */
    getEmployeeShop(user_id,fn){
        let param = { page:1,limit:999,user_id}
        $post("allocUserProgramList",param,true,1).then(res=>{
            fn(res.data)
        })
    }

    /**
     * 给员工分配店铺
     * @param {number} user_id  员工id
     * @param {array} program_ids 店铺ID
     * @param {function} fn 
     * @returns 
     */
    setEmployeeShop(user_id,program_ids,fn){
        if( program_ids.length == 0 ){
            tool.message("请选择要添加的店铺","warning")
            return
        }
        let param = { user_id,program_ids}
        $post("allocUserProgram",param,true,1).then(res=>{
            tool.message("设置成功")
            fn(res.data)
        })
    }

    /**
     * 切换员工的禁用/启用状态
     * @param {number} user_id  员工user_id
     * @param {number} status   切换状态
     * @param {function} fn 
     */
    changeEmployeeStatus(user_id,status,fn){
        let tips = status == 0 ? "确认禁用该员工吗?":"确认启用该员工吗?"
        tool.confirm(tips).then(()=>{
            $post("delChildUser",{id:user_id,status},true,1).then(res=>{
                tool.message("操作成功")
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 可分配应用的用户
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getMerchantUserList(page,limit,extra,fn){
        let param = {page,limit}
        if( extra && extra.keyword ) param.keyword = extra.keyword
        commonModel.getDataList("getMerchantUserList",param,true,1).then(res=>fn(res))
    }

    /**
     * 新增商户
     * @param {object} param 
     * @param {function} cb 
     */
    addMerchantUser(param,cb){
        if( param.sence == 'password'){ //修改密码
            if( !tool.returnMessage(param.password,'请输入新密码')) return
            if( !tool.returnMessage(param.re_password,'请再次输入新密码')) return
            if( param.password !=param.re_password ){
                tool.message("两次输入密码不一致")
                return
            }
            $post('addOrEditMerchantUser', data,true, 1).then(res => {
				tool.message("密码修改成功")
                cb(res.data)
			})
            return
        }

        if( param.validity_time ){
            param.validity_time = moment(param.validity_time).format('YYYY-MM-DD')
        }
        $post('addOrEditMerchantUser', param,true, 1).then(res => {
            tool.message('保存成功')
            cb(res.data)
        })
    }

    /**
     * 根据模块获取权限
     * @param {string} addons 
     * @param {function} cb 
     */
    getAuthMenuByAddon(addons,cb){
        $post('getAuthRoute', { addons }, true,1).then(res => cb(res.data))
    }

    /**
     * 获取已添加的路由列表
     * @param {function} cb 
     */
    getAuthMenuList(cb) {
        $post('getAuthMenuList', {},true, 1).then(res => cb(res));
    }

    /**
     * 新增或编辑菜单信息
     * @param {object} param 
     * @param {function} cb 
     */
    addOrEditMenu(param,cb) {
        if (param.menu_id == 0) delete param.menu_id;
        if ( !tool.returnMessage(param.menu_name,'请填写菜单名称')) return
        $post('addOrEditAuthMenu', param,true, 1).then(res => {
            tool.message('保存成功');
            cb(res.data)
        })
    }

    /**
     * 删除菜单
     * @param {number} id 
     * @param {function} cb 
     */
    deleteMenu(id,cb){
        tool.confirm("确认删除菜单信息吗？").then(()=>{
            $post('delAuthMenu', { menu_id: id },true, 1).then(() => {
                tool.message('删除成功');
                cb(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 菜单操作
     * @param {string} type  node改变节点 sort排序
     * @param {object} param 
     */
    handleMenu(type,param){
        if( type =='node' ){
            $post('setAuthMenuNode', param,true, 1).then(() => {
				console.log('节点改变成功');
			})
        }
        if( type =='sort' ){
            $post('setAuthMenuSort', param,true, 1).then(() => {
				console.log('排序成功');
			})
        }
    }
}

const authModel =new Auth()
export default authModel